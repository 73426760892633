/*jshint browser: true, devel: true */
/*global Siema window */


var findAncestor = function (el, cls) {
    "use strict";

    while (!el.classList.contains(cls)) {
        el = el.parentElement;
    }
    return el;
};

var findSibling = function (el, cls) {
    "use strict";

    return el.parentNode.querySelector(cls);
};

document.addEventListener("DOMContentLoaded", function () {
    "use strict";

    var galleryEls = document.querySelectorAll(".js-gallery");
    var expandEls = document.querySelectorAll(".js-expand");


    var toggle_el = document.querySelector(".menu-toggle");
    var menu_el = document.getElementById("primary-navigation");
        
    toggle_el.addEventListener("click", function(event) {
        event.preventDefault();
        
        // cast string to boolean
        var is_expanded = (toggle_el.getAttribute("aria-expanded") == 'true');
        
        // invert boolean value to toggle on / off
        toggle_el.setAttribute("aria-expanded", !is_expanded);
        toggle_el.classList.toggle("is-active", !is_expanded);
        menu_el.classList.toggle("is-expanded", !is_expanded);
    });

    expandEls.forEach(function(expandEl) {
        expandEl.addEventListener("click", function() {
            expandEl.parentNode.classList.toggle("expanded");
        });
    });

    galleryEls.forEach(function (galleryEl) {

        var parent = findSibling(galleryEl, ".js-expand");

        var instance = new Siema({
            selector: galleryEl.querySelector(".js-gallery__list"),
            duration: 200,
            easing: "ease-out",
            perPage: 1,
            startIndex: 0,
            draggable: true,
            multipleDrag: true,
            threshold: 20,
            loop: true
        });

        galleryEl.querySelector(".js-prev").addEventListener("click", function (event) {
            event.preventDefault();
            return instance.prev();
        });

        galleryEl.querySelector(".js-next").addEventListener("click", function (event) {
            event.preventDefault();
            return instance.next();
        });

        /**
         * Re-init the slider since the images are hidden by default
         */
        if (parent) {
            parent.addEventListener("click", function () {
                console.log("Expanded");
                instance.resizeHandler();
            }, {once: true});
        }
    });
});